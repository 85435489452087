import { CommonModule, DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Calendar } from '../models/calendar';
import { Day } from '../models/day';
import { BehaviorSubject, combineLatest, distinctUntilChanged, map, Observable, ReplaySubject } from 'rxjs';
import { DayUserEvent } from '../models/day-user-event';
import { YearMonth } from '../models/year-month';
import { IconComponent } from '../widgets/icon/icon.component';

interface calDay{
  fullDate: string;
  dayOfWeek: number;
  number: string;
  nbEvents : number;
  isConflictEvent: boolean;
  isToday: boolean;
  isSelected: boolean;
  isLeaveDay: boolean;
  sumEventsDay: number
}

@Component({
  selector: 'app-calendar',
  standalone: true,
  imports: [CommonModule, IconComponent],
  templateUrl: './calendar.component.html',
  styleUrl: './calendar.component.scss'
})

export class CalendarComponent {
  calendar!: Calendar;
  tooltipOpen: boolean = false;

  daySelected!: Day | null;
  todayDate = this.datePipe.transform(new Date(),"yyyyMMdd");
 
  private __displayedMonth$: ReplaySubject<YearMonth> = new ReplaySubject<YearMonth>(1);
  private __events$: BehaviorSubject<DayUserEvent[]> = new BehaviorSubject<DayUserEvent[]>([]);
  private __selectedDay$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  public calDays$: Observable<calDay[]>;

  //Period displayed
  @Input() set displayedMonthYear$(newMonthYear: YearMonth | null){
    if(newMonthYear === null){  return;  }    
    this.__displayedMonth$.next(newMonthYear);
  }

  //All user Event
  @Input() set allEvents$(newEvents: DayUserEvent[] | null){
    if(newEvents === null) return;
    this.__events$.next(newEvents);
  }

  //Listen day selection from parent
  @Input() set selectDay(newSelectd: string | null) {
    if(newSelectd === null) return;    
    this.__selectedDay$.next(newSelectd);
  };

  @Output() dayClicked: EventEmitter<string> = new EventEmitter<string>();
  
  constructor(
    private datePipe: DatePipe,
  ) { 

    // pipes intermédiaires pour éviter la cohue dans le combineLatest()
    const days$ = this.__displayedMonth$.pipe(
      map((displayedMonth) => this.generateView(displayedMonth))
    );

    const selection$ = this.__selectedDay$.pipe(
      distinctUntilChanged(),
    );

    //
    const daysWithEvents$ = combineLatest([this.__events$, days$]).pipe(
      map(([events, days]) => {        
        days.forEach(d => {          
          //Get conflict on day
          d.isConflictEvent = events.some((e) => e.DayUserEventDate === d.fullDate && e.DayUserEventConflict);
          //Get number of events in the day
          d.nbEvents = events.map((e) => e.DayUserEventDate === d.fullDate ? e.DayUserEvents.length : 0).reduce((acc, cur) => acc + cur, 0);
          //Get if day contain leave events 
          d.isLeaveDay = events
            .filter(e => e.DayUserEventDate === d.fullDate)
            .some(e => e.DayUserEvents.some(ev => ev.EventType === "A"));

          //Get if day contain leave events 
          d.isLeaveDay = events
            .filter(e => e.DayUserEventDate === d.fullDate)
            .some(e => e.DayUserEvents.some(ev => ev.EventType === "A"));

          //Get Day Events state minimum to display the correct indicator color  
          d.sumEventsDay = events
          .filter(e => e.DayUserEventDate === d.fullDate)
          .flatMap(e => e.DayUserEvents.map(ev => {
            let newEventState: number = -1;
            
            switch (ev.EventState) {
              case 1: //Red
                newEventState = 0
                break;
              case 2: // Orange or green
                newEventState = ev.EventConfirmConsult ? 2 : 1
                break;
              case 3: // No color
                newEventState = 4
                break;
              default:
                newEventState = -1
                break;
            }
            return newEventState
          })) // Récupère tous les EventState
          .reduce((min, cur) => Math.min(min, cur), Infinity); // Trouve le minimum
        });
        
        return days;
      })
    );

    this.calDays$ = combineLatest([
      daysWithEvents$,
      selection$
    ]).pipe(
      map(([days, selected]) => {
        days.forEach(d => {
          d.isSelected = d.fullDate === selected
        })
        
        return days;
      })
    );
  }

  clickOnDay(dayClicked: string) {    
    this.dayClicked.emit(dayClicked);
  }

  private generateView(yearMonth : YearMonth) : calDay[] {
    const days : calDay[] = [];

    const year = yearMonth.year;
    const month = Number(yearMonth.month) - 1; // months in JS are 0-indexed;

    for(let i = 1, l = 32; i < l ; i+= 1){
      const dt = new Date(year, month, i);
      if(dt.getMonth() !== month){ 
        break;
      }

      if (year == 2025) {
      }

      days.push({
        dayOfWeek: dt.getDay(),
        fullDate: this.datePipe.transform(dt,"yyyyMMdd") || '',
        isToday: dt.toLocaleDateString() === (new Date()).toLocaleDateString(),
        number: i.toString(),
        nbEvents: 0,
        isConflictEvent: false,
        isSelected: false,
        isLeaveDay: false,
        sumEventsDay: 0
      })
    }

    return days;
  }
  
}
