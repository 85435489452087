import { CommonModule, DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { IconComponent } from '../widgets/icon/icon.component';
import { Event } from '../models/event';
import { CostCardComponent } from "../cost-card/cost-card.component";
import { TooltipCostComponent } from "../tooltip-cost/tooltip-cost.component";
import { EventCost, EventCostAuto, EventCostObject, EventCostTabObject } from '../models/event-cost';
import { EventService } from '../services/event.service';
import { CostService } from '../services/cost.service';
import { catchError, forkJoin, map, Observable, of } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { UserService } from '../services/user.service';
import { NetworkStatusService } from '../services/network-status.service';
import { FormatDatePipe } from '../pipe/format-date.pipe';
import { log } from 'console';

@Component({
  selector: 'app-cost-list',
  standalone: true,
  imports: [IconComponent, FormsModule, ReactiveFormsModule, CommonModule, CostCardComponent, TooltipCostComponent, FormatDatePipe],
  providers: [DatePipe], //{provide: LOCALE_ID, useValue: 'fr' }
  templateUrl: './cost-list.component.html',
  styleUrl: './cost-list.component.scss'
})
export class CostListComponent {

  event!: Event;
  tooltipOpen: boolean = false;
  modalOpen: boolean = false;
  modalOpen2: boolean = false;
  callBackModal: boolean | undefined = undefined;
  eventHasNoCost: boolean = false;

  //Authorisation User
  isUserAuthorized: boolean = false;
  online$: Observable<boolean>;
  private changed: boolean = false;

  sortedGroupedCosts: { [key: string]: any[] } = {};

  constructor (
    private userSvc: UserService,
    private router: Router,
    //private activatedRoute: ActivatedRoute
    private datePipe: DatePipe,
    private eventSvc: EventService,
    private costSvc: CostService,
    statusSvc: NetworkStatusService
  ) {

    this.online$ = statusSvc.isOnline$;

    const navigation = this.router.getCurrentNavigation();
    
    const state = navigation?.extras.state as { _event: Event };
    if (state) {
      this.event = state._event;
      this.eventHasNoCost = this.event.EventisCost;
      this.orderSortCost(this.event.EventCosts);
    }

    //Authorisation User
    this.checkUserAuthorisation();
  }

  orderSortCost(_eventCosts: EventCost[]) {
    //Group by date
    const groupedCosts = _eventCosts.reduce((acc, cost) => {
      const date = cost.EventCostDate;
      if (!acc[date]) {
          acc[date] = [];
      }
      acc[date].push(cost);
      return acc;
    }, {} as Record<string, typeof _eventCosts>);

    //Sort by date - used to loop on this element instead of Event.EventCosts
    this.sortedGroupedCosts = Object.keys(groupedCosts)
    .sort()
    .reduce((acc, date) => {
        acc[date] = groupedCosts[date];
        return acc;
    }, {} as Record<string, typeof _eventCosts>);
  }

  backToEvent() {
    let _event: Event = this.event;

    if (this.event) {
      this.router.navigate(['event/', _event.EventId], {
        state: { _event: _event, _changed: this.changed }
      });
    }
  }

  saveEvent() {
    this.event.EventisCost = this.eventHasNoCost;
    this.eventSvc.modifEvent(this.event, +true).subscribe({
      next: (_response) => {
        this.changed = true;
      },
      error: (_error) => {
        console.error("Erreur lors de la modification de l'évènement", _error);
        if (_error.error) {
          alert("Erreur lors de la modification de l'évènement : " + _error.error.Error.ErrorMessage);
        }
      }
    });
  }

  getDate(_date: string) {
    let date = this.datePipe.transform(_date, 'fullDate', 'fr');
    return date;
  }

  newEventCost(_eventCostAdded: EventCost, _forcedSecondtTime: boolean = false) {
    this.costSvc.createEventCost(this.event.EventId, _eventCostAdded, +_forcedSecondtTime).subscribe(
      {
        next: (_response: EventCostObject) => {
          if (_response.Error.ErrorCode == 206) {
            if (confirm(_response.Error.ErrorMessage)) {
              this.newEventCost(_eventCostAdded, true);
            } else {
              return;
            }
          } else {
            this.changed = true;
            this.event.EventCosts.push(_response.EventCost);
            this.orderSortCost(this.event.EventCosts);
            this.calculTotalCost();
          }
        },
        error: (_error: HttpErrorResponse) => {
          console.error("Erreur lors de la création d'un cost", _error);    
          if (_error.error) {
            alert("Erreur lors de la création d'un frais : " + _error.error.Error.ErrorMessage);
          }
        }
      }
    );
  }

  newEventCostAuto(_eventCostAutoAdded: EventCostAuto) {
    this.costSvc.createEventCostAuto(this.event.EventId, _eventCostAutoAdded).subscribe(
      {
        next: (_response: EventCostTabObject) => {
          this.event.EventCosts = this.event.EventCosts.filter(_eventCost => _eventCost.EventCostType.CostTypeAuto == false);
          if (_response.EventCosts.length > 0) {
            _response.EventCosts.map(_costAuto => {
              this.event.EventCosts.push(_costAuto);
            });
          }
          this.changed = true;
          this.orderSortCost(this.event.EventCosts);
          this.calculTotalCost();
        },
        error: (_error) => {
          if (_error.error) {
            alert("Erreur lors de la création d'un cost automobile : " + _error.error.Error.ErrorMessage);
          }
        }
      }
    );
  }

  deleteEventCost(_eventCostDeleted: EventCost, _multipleDelete: boolean = false) {

    if (_eventCostDeleted.EventCostId <= 0) {
      alert("Suppression du cost : " + _eventCostDeleted.EventCostDetail + " impossible")
      return;
    }

    if (!_multipleDelete) {
      if (!confirm("Confirmez-vous la suppression de ce frais ?")) {
        return;
      }
    }

    this.costSvc.deleteEventCost(_eventCostDeleted).subscribe(
      {
        next: (_response) => {
          this.event.EventCosts = this.event.EventCosts.filter(_cost => _cost.EventCostId != _eventCostDeleted.EventCostId);
          /* this.orderSortCost(this.event.EventCosts); */
          this.changed = true;
          this.calculTotalCost();
        },
        error: (_error) => {
          console.error("Erreur lors de la création d'un frais", _error);    
          if (_error.error) {
            alert("Erreur lors de la suppresion d'un frais : : " + _error.error.Error.ErrorMessage);
          }
        }
      }
    );
  }

  calculTotalCost() {
    this.event.EventTotalCost = 0;

    this.event.EventCosts.map(_cost => {
      this.event.EventTotalCost += Number(_cost.EventCostTTC);
    });

    this.event.EventTotalCost = Number(this.event.EventTotalCost.toFixed(2));
  }

  changeCostCheckValue() {
    let _checkboxValue = !this.eventHasNoCost;

    if (_checkboxValue == false) {
      this.eventHasNoCost = _checkboxValue;
      this.saveEvent();
    } else {
      if (this.event.EventCosts.length > 0) {
        //this.openModal();
        if (confirm("Si vous cochez la case "+'"Aucun Frais"'+", tous les frais présent dans la liste seront supprimés. Confirmez-vous cette action ?")) {

          this.deleteEventCosts(this.event.EventCosts, true);
          this.eventHasNoCost = _checkboxValue;
          
        } else {
          //Rollback we put checkbox to false and do nothing
          this.eventHasNoCost = false;
        }
      } else {
        this.eventHasNoCost = true;
        this.saveEvent();
      }
    }
  }

  /* openModal(_callBack?: boolean) {
    this.modalOpen = !this.modalOpen;
    this.callBackModal = _callBack;

    if (this.callBackModal) {
      this.deleteAllEventCost();
    } else {
      this.eventHasNoCost = true;
    }
  } */

  //Authorisation User
  checkUserAuthorisation() {    
    this.isUserAuthorized = this.userSvc.getUserAuthorisation(this.userSvc.getUserChosen().UserId);
  }

  deleteEventCosts(eventCostsToDelete: EventCost[], _multipleDelete: boolean = false) {

    if (!_multipleDelete) {
      if (!confirm("Confirmez-vous la suppression de ce frais ?")) {
        return;
      }
    }    

    if (eventCostsToDelete.length === 0) return;
  
    // Build Observable array for each delete
    const deleteRequests = eventCostsToDelete.map(eventCost =>
      this.costSvc.deleteEventCost(eventCost).pipe(
        map(() => ({ success: true, eventCost })), // Success
        catchError(error => {
          console.error("Erreur lors de la suppression d'un frais", error);
          alert(`Erreur suppression (${eventCost.EventCostDetail}): ${error.error?.Error?.ErrorMessage || "Erreur inconnue"}`);
          return of({ success: false, eventCost }); // Échec mais on continue
        })
      )
    );
  
    // Execut multiple request in same time
    forkJoin(deleteRequests).subscribe(results => {
      // Split success and failure
      const successResults = results.filter(r => r.success);
      const errorResults = results.filter(r => !r.success);
  
      // Delete sucess 
      this.event.EventCosts = this.event.EventCosts.filter(
        cost => !successResults.some(r => r.eventCost.EventCostId === cost.EventCostId)
      );
  
      this.changed = true;
      this.orderSortCost(this.event.EventCosts);
      this.calculTotalCost();
  
      // Log result
      console.log(`Suppression terminée : ${successResults.length} réussites, ${errorResults.length} échecs.`);

      // Save if all success 
      if (errorResults.length === 0) {
        if (this.event.EventCosts.length == 0) {
          this.eventHasNoCost = true;
        }
        this.saveEvent();
      } else {
        alert(`Certaines suppressions ont échoué (${errorResults.length}/${results.length}).`);
      }
    });
  }
}
