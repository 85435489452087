<div class="event-form-container">
    <div class="event-form-header">
        <app-icon class="event-form-close" (click)="backToCalendar()" [iconLabel]="'close'"></app-icon>
        <h1 class="regular">{{userEvent!.UserShortName}}</h1>
    </div>
    <div class="event-form-sidebar">
        <div class="event-form-sidebar-icon">
            <app-icon (click)="openCostList()" [iconLabel]="'euro'"></app-icon>
            @if (event.EventCosts.length > 0 || event.EventisCost) {
                <div class="icon-indicator"></div>
            }
        </div>
        <div class="event-form-sidebar-icon">
            <app-icon iconLabel="planning-fa" (click)="openPlanningFa()" [ngClass]="{'offline' : !(online$ | async)}"></app-icon>
        </div>
        <div class="event-form-sidebar-icon">
            <app-icon [iconLabel]="'mail'" [ngClass]="{'offline' : !(online$ | async) || !event.EventFA.FicheActionLinkBrief}" (click)="openLink(event.EventFA.FicheActionLinkBrief)"></app-icon>
        </div>
        <div class="event-form-sidebar-icon">
            <app-icon [iconLabel]="'file'" [ngClass]="{'offline' : !(online$ | async) || !event.EventFA.FicheActionLinkOffer}" (click)="openLink(event.EventFA.FicheActionLinkOffer)"></app-icon>
        </div>
    </div>
    <div class="event-form-content-top">
        <form [formGroup]="eventForm">
            <div class="form-input">
                <div class="section-subtitle">
                    <h5 class="bold mission-input">Mission : {{event.EventLabelFA}}</h5> 
                    <h5 class="bold special-input">{{event.EventLabelMission}}</h5> 
                </div>
            </div>
            <div class="form-input">
                <h5 class="bold">Date :</h5> 
                <div class="special-input-date">
                    <input formControlName="eventDate" type="date" #datePicker>
                    <app-icon (click)="datePicker.showPicker()" [iconLabel]="'date-calendar'" class="special-input-date-icon"></app-icon>
                </div>
            </div>
            <div class="form-input">
                <h5 class="bold">Horaires :</h5> 
                <div class="special-input-date">
                    <input formControlName="eventStart" type="time" #timePickerStart>
                    <app-icon (click)="timePickerStart.showPicker()" [iconLabel]="'time-clock'" class="special-input-date-icon"></app-icon>
                </div>
                <h5 class="bold">à</h5> 
                <div class="special-input-date">
                    <input formControlName="eventEnd" type="time" #timePickerEnd>
                    <app-icon (click)="timePickerEnd.showPicker()" [iconLabel]="'time-clock'" class="special-input-date-icon"></app-icon>
                </div>
            </div>
            <div class="form-input">
                <h5 class="bold">Statut :</h5> 
                <select formControlName="eventState" class="custom-select">
                    <option [value]="state.ComboValueId" *ngFor="let state of stateCombo.ComboValues"><h6 class="regular">{{state.ComboValueLabel}}</h6></option>
                </select>
            </div>
            <div class="form-input centered-input">
                <input type="checkbox" formControlName="eventConfirmConsult"/>
                <h6 class="regular">Confirmé consultant</h6> 
            </div>
        </form>
    </div>

    <div class="event-form-content-bottom">
        <form [formGroup]="eventForm">
            @if (event.EventFA.FicheActionSellerName) {
                <div class="form-input centered-input seller-container">
                    <h5 class="bold seller-title">Vendeur : </h5>
                    <h6 class="regular seller-content">{{event.EventFA.FicheActionSellerName}}</h6> 
                </div>
            }
          
            @if (isBillableOfferedVisible) {
                <div class="form-input centered-input">
                    <div class="switch-input">
                        <div class="switch-input-left" [ngClass]="{'selected': !event.EventOffered, 'selected-disabled': (!isUserAuthorized && !event.EventOffered)}" (click)="changeInputOffered(false)">
                            <h6 [ngClass]="!event.EventOffered ? 'regular' : 'light'">Facturé</h6>
                        </div>
                        <div class="switch-input-right" [ngClass]="{'selected': event.EventOffered, 'selected-disabled': (!isUserAuthorized && event.EventOffered)}" (click)="changeInputOffered(true)">
                            <h6 [ngClass]="event.EventOffered ? 'regular' : 'light'">Offert</h6>
                        </div>
                    </div>
                </div>
            }

            <div class="form-input centered-input">
                <h5 class="bold" >N° de journée :</h5> 
                <input type="text" [placeholder]="'Nombre de journée'" formControlName="eventNumberDay"/>
            </div>
            @if (event.EventRooms !== "") {
                <div class="form-input centered-input">
                    <h5 class="bold">Salle(s) : {{event.EventRooms}}</h5> 
                </div>
            }
            <div class="form-input centered-input">
                <!-- Ticket restaurant -->
                <div class="ticket-container">
                    <app-icon iconLabel="ticket-restaurant"></app-icon>
                    @if (!event.EventTR) {
                        <app-icon class="cross" iconLabel="cross-red"></app-icon>
                    }
                </div>
                 

                <div class="switch-input">
                    <div class="switch-input-left" [ngClass]="{'selected': !event.EventRemote, 'selected-disabled': (!isUserAuthorized && !event.EventRemote)}" (click)="changeInputRemote(false)">
                        <h6 [ngClass]="!event.EventRemote ? 'regular' : 'light'">Présentiel</h6>
                    </div>
                    <div class="switch-input-right" [ngClass]="{'selected': event.EventRemote, 'selected-disabled': (!isUserAuthorized && event.EventRemote)}" (click)="changeInputRemote(true)">
                        <h6 [ngClass]="event.EventRemote ? 'regular' : 'light'">Distanciel</h6>
                    </div>
                </div>
            </div>
            <div class="form-input centered-input">
                <h5 class="bold">Adresse :</h5> 
                <input type="text" class="adress-input" [placeholder]="'Adresse'" formControlName="eventPlace"/>
                @if (this.eventForm.get('eventPlace')?.value) {
                    <div class="button-googlemap">
                        <div class="icon-map">
                            <app-icon iconLabel="map-marker" (click)="openGoogleMapAdress()"></app-icon>
                        </div>
                    </div>
                }
            </div>
            <div class="form-input centered-input">
                <input type="checkbox" formControlName="eventCompanionship"/>
                <h6 class="regular">Compagnonnage</h6> 
            </div>
            <div class="form-input centered-input">
                <h6 class="regular">{{event.EventStaffedWith}}</h6> 
            </div>
            <div class="form-input-textarea">
                <textarea [placeholder]="'Action prévues'" formControlName="eventPlannedActions" maxlength="150"></textarea>
                <textarea [placeholder]="'Contraintes personelles'" formControlName="eventConstraint" maxlength="150"></textarea>
            </div>
        </form>
    </div>
    <div class="event-form-button">
        @if (this.eventForm.dirty && isUserAuthorized && (online$ | async)) {
            <button (click)="modifyEvent()"><h5 class="light">Valider</h5></button>
        }
    </div>
</div>