<div class="event-card-container">
    <div class="event-card">
        <div class="event-card-left" (click)="clickOnEvent(event)">
            <div class="event-card-hour">
                <h4 class="light">{{event.EventStart}}</h4>
                <!-- State event icon -->
                @switch (event.EventState) {
                    @case (1) {
                       <!-- No icon -->
                    }
                    @case (2) {
                        @if (event.EventConfirmConsult) {
                            <app-icon class="icon-event-type" [iconLabel]="'event-checkbox'"></app-icon>
                        } @else { 
                            <app-icon class="icon-event-type" [iconLabel]="'event-clock'"></app-icon>
                        }
                    }
                    @case (3) {
                        <app-icon class="icon-event-type" [iconLabel]="'event-check'"></app-icon>
                    }
                }
                <h4 class="light">{{event.EventEnd}}</h4>
            </div>
            <h3 class="light card-title">{{event.EventLabelMission}}</h3>
            <div class="card-subtitle-container">
                <h6 class="light card-subtitle">{{event.EventLabelFA}}</h6>
                @if (event.EventPlace && isEventPlaceDisplayed) {
                    <h6 class="light card-subtitle">{{event.EventPlace}}</h6>
                }
            </div>
        </div>
        <div class="event-card-right"> 
            @if (event.EventType != "A" && isDeletable) { <!-- eventObj.EventType != "A" ||  -->
                <app-icon class="btn-cross" [iconLabel]="'cross'" (click)="clickDeleteEvent()"></app-icon>
            }
        </div>
    </div>
</div>