<div class="calendar-header">
    <div><h6 class="bold dayLabel">L</h6></div>
    <div><h6 class="bold dayLabel">M</h6></div>
    <div><h6 class="bold dayLabel">M</h6></div>
    <div><h6 class="bold dayLabel">J</h6></div>
    <div><h6 class="bold dayLabel">V</h6></div>
    <div><h6 class="bold dayLabel">S</h6></div>
    <div><h6 class="bold dayLabel">D</h6></div>
</div>
<div class="calendar-content">
    @if(calDays$ | async; as days){
        @for(day of days; track day.fullDate){
            <div 
              class="day dow-{{day.dayOfWeek}}" 
              (click)="clickOnDay(day.fullDate)" 
              [ngClass]="{'day-first': day.number === '1'}"
            >
                <div class="day-button numberDayBlock hover-circle" [ngClass]="{ 'day-event-color-selected' : day.isSelected, 'day-today-color' : day.isToday }">
                    <h6 class="light dayNumber">
                        {{day.number}}
                    </h6>
                    <div class="day-conflict-warn">
                        @if (day.isConflictEvent) {
                            <app-icon iconLabel="calendar-mask/day-events-conflict"></app-icon>
                        }
                    </div>

                    @if (day.nbEvents > 0) {
                        <!-- State event indicator -->
                        @switch (day.sumEventsDay) {
                            <!-- Red Indicator -->
                            @case (0) {
                                <div class="day-state-indicator indicator-color-red"></div>
                            }
                            <!-- Orange Indicator -->
                            @case (1) {
                                <div class="day-state-indicator indicator-color-orange"></div>
                            }
                            <!-- Green Indicator -->
                            @case (2) {
                                <div class="day-state-indicator indicator-color-green"></div>
                            }
                        }
                    }
                </div>
                <!-- Mask on day -->
                @if (day.nbEvents > 0 && !day.isSelected && !day.isLeaveDay) {
                    <app-icon class="mask-day" iconLabel="calendar-mask/event-day"></app-icon>
                } @else if (day.isSelected && !day.isLeaveDay) {
                    <app-icon class="mask-day" iconLabel="calendar-mask/day-selected"></app-icon>
                } @else if (day.isLeaveDay && !day.isSelected) {
                    <app-icon class="mask-day" iconLabel="calendar-mask/leave-day"></app-icon>
                } @else if (day.isLeaveDay && day.isSelected) {
                    <app-icon class="mask-day" iconLabel="calendar-mask/leave-day-selected"></app-icon>
                }
            </div>
        }
    }
</div>