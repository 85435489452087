<div class="tooltip-background" (click)="tooltipOpen = !tooltipOpen" [ngClass]="{'tooltip-close' : !tooltipOpen}"></div>
<div class="tooltip" [ngClass]="{'tooltip-close' : !tooltipOpen}">
    <div class="triangle"></div>
    <div class="tooltip-header">
        <app-icon class="small-icon" iconLabel="cross-blue" (click)="tooltipOpen = !tooltipOpen"></app-icon>
        <div class="big-icon-content"> 
            @if (!isTravelCost) {
                <app-icon class="big-icon" iconLabel="restauration-orange" (click)="isTravelCost = false"></app-icon>
                <app-icon class="big-icon" iconLabel="car" (click)="isTravelCost = true"></app-icon>
            } @else {
                <app-icon class="big-icon" iconLabel="restauration" (click)="isTravelCost = false"></app-icon>
                <app-icon class="big-icon" iconLabel="car-orange" (click)="isTravelCost = true"></app-icon>
            }
        </div>
        <div class="fake-icon"></div>
    </div>
    <div class="tooltip-content">
        @if (!isTravelCost) {
            <form [formGroup]="costForm" class="tooltip-form">
                <div class="form-input">
                    <h6 class="regular">Type de frais</h6> 
                    <select formControlName="costType" class="custom-select" (ngModelChange)="onChangeCostType($event)">
                        <option value="0">Choisir un type</option>
                        <option [value]="type.CostTypeId" *ngFor="let type of typeComboList"><h6 class="regular">{{type.CostTypeLabel}}</h6></option>
                    </select>
                </div>
                <div class="form-input">
                    <h6 class="regular">Détail du frais</h6> 
                    <input formControlName="costDetail" type="text" placeholder="Détail du frais" onfocus="this.select()">
                </div> 
                <div class="form-input-half">
                    <h6 class="regular">TTC</h6> 
                    <input formControlName="costTTC" type="text" inputmode="numeric" onfocus="this.select()">
                </div>
                @if (costTypeSelected && costTypeSelected.CostTypeWithAmountTVA) {
                    <div class="form-input-half">
                        <h6 class="regular">TVA</h6> 
                        <input formControlName="costTVA" type="text" inputmode="numeric" onfocus="this.select()">
                    </div>
                }
                @if (costTypeSelected && costTypeSelected.CostTypeWithOvernight) {
                    <div class="form-input-half">
                        <h6 class="regular">Nuitée</h6>
                        <input formControlName="costOvernight" type="text" inputmode="numeric" onfocus="this.select()">
                    </div>
                }
                @if (costTypeSelected && costTypeSelected.CostTypeWithKmCO2) {
                    <div class="form-input-half">
                        <h6 class="regular">KmCO2</h6>
                        <input formControlName="costKmCO2" type="text" inputmode="numeric" onfocus="this.select()">
                    </div>
                }
                <div class="form-duo">
                    <label class="form-input-half">
                        <input type="checkbox" formControlName="costRebillable"/>
                        <h6 class="regular">Refacturable Client</h6> 
                    </label>
                    <label class="form-input-half">
                        <input type="checkbox" formControlName="costExpense"/>
                        <h6 class="regular">Note de frais</h6> 
                    </label>
                </div>
                <div class="form-input centered">
                    <h6 class="regular">Date de la dépense</h6> 
                    <div class="special-input-date">
                        <input formControlName="costDate" type="date" #datePicker>
                        <app-icon (click)="datePicker.showPicker()" [iconLabel]="'date-calendar'" class="special-input-date-icon"></app-icon>
                    </div>
                </div>
            </form>
        } @else {
            <form [formGroup]="costFormAuto" class="tooltip-form">
                <div class="form-input">
                    <h6 class="regular">Véhicule</h6> 
                    <select formControlName="costVehicule" class="custom-select">
                        <option value="0">Choisir un véhicule</option>
                        <option [value]="car.ComboValueId" *ngFor="let car of carCombo"><h6 class="regular">{{car.ComboValueLabel}}</h6></option>
                    </select>
                </div>
                <h3 class="bold tooltip-form-title">Déplacement</h3>
                <div class="form-input">
                    <h6 class="regular">Coût du kilomètre :</h6> 
                    <h6 class="regular">{{ficheAction.FicheActionCostByKm}} €</h6> 
                </div>
                <div class="form-input-half">
                    <h6 class="regular">Kilométrage (A/R)</h6> 
                    <input formControlName="costKilometers" type="text" onfocus="this.select()">
                </div>
                <div class="form-input-half">
                    <h6 class="regular">Péages (A/R)</h6> 
                    <input formControlName="costHighwayToll" type="text" onfocus="this.select()">
                </div>
            </form>
        }
    </div>
    
    <div class="tooltip-button-form">
        <button (click)="createCost()"><h5 class="light">Créer</h5></button>
    </div>
</div>
