<div class="cost-card" (click)="changeHeight()" [ngClass]="(deployed) ? 'cost-card-long' : 'cost-card-little'">
    <div class="cost-card-left">
        <form [formGroup]="costForm" class="cost-card-form">
            <div class="form-input">
                <h6 class="regular">Type de frais</h6> 
                <select formControlName="costType" class="custom-select">
                    <option value="default">Choisir un type</option>
                    <option [value]="type.CostTypeId" *ngFor="let type of typeCombo"><h6 class="regular">{{type.CostTypeLabel}}</h6></option>
                </select>
            </div>
            <div class="form-input">
                <h6 class="regular">Détail du frais</h6> 
                <input formControlName="costDetail" type="text" class="input-detail">
            </div> 
            <div class="form-input-list">
                <div class="form-input-half">
                    <h6 class="regular">TTC</h6> 
                    <input formControlName="costTTC" type="text">
                </div>
                @if (cost.EventCostType.CostTypeWithAmountTVA) {
                    <div class="form-input-half">
                        <h6 class="regular">TVA</h6> 
                        <input formControlName="costTVA" type="text">
                    </div>
                }
                @if (cost.EventCostType.CostTypeWithKmCO2) {
                        <div class="form-input-half">
                            <h6 class="regular">KmCO2</h6> 
                            <input formControlName="costKmCO2" type="text">
                        </div>
                }
                @if (cost.EventCostType.CostTypeWithOvernight) {
                    <div class="form-input-half">
                        <h6 class="regular">Nuitée</h6> 
                        <input formControlName="costOvernight" type="text">
                    </div>
                }
            </div>
            <!-- <div class="form-duo">
                
                <div class="form-input-half">
                    <h6 class="regular">TTC</h6> 
                    <input formControlName="costTTC" type="text">
                </div>
                @if (cost.EventCostType.CostTypeWithAmountTVA) {
                    <div class="form-input-half">
                        <h6 class="regular">TVA</h6> 
                        <input formControlName="costTVA" type="text">
                    </div>
                }
            </div>
            @if (!deployed) {
                <div class="form-duo">
                    @if (cost.EventCostType.CostTypeWithKmCO2) {
                        <div class="form-input-half">
                            <h6 class="regular">KmCO2</h6> 
                            <input formControlName="costKmCO2" type="text">
                        </div>
                    }
                    @if (cost.EventCostType.CostTypeWithOvernight) {
                        <div class="form-input-half">
                            <h6 class="regular">Nuitée</h6> 
                            <input formControlName="costOvernight" type="text">
                        </div>
                    }
                </div>
            } -->
            <div class="form-duo">
                <div class="form-input-half">
                    <input type="checkbox" formControlName="costRebillable"/>
                    <h6 class="regular">Refacturable Client</h6> 
                </div>
                <div class="form-input-half">
                    <input type="checkbox" formControlName="costExpense"/>
                    <h6 class="regular">Note de frais</h6> 
                </div>
            </div>
        </form>
    </div>
    <div class="cost-card-right"> 
        <div class="icon-right-card icon-right-cross">
            @if (isDeletable) {
                <app-icon [iconLabel]="'cross'" (click)="deleteCost()"></app-icon>
            }
        </div>
        <app-icon class="icon-right-card" [iconLabel]="deployed ? 'cost-card-long' : 'cost-card-little'" [ngClass]="(deployed) ? 'cost-card-long' : 'cost-card-little'"></app-icon>
    </div>
</div>