import { Error } from "./error";

export interface EventCost {
    EventCostId: number;
    EventCostDetail: string;
    EventCostTTC: number;
    EventCostTVA: number;
    EventCostOvernight: number;
    EventCostKmCO2: number;
    EventCostRebillable: boolean;
    EventCostExpense: boolean;
    EventCostType: EventCostType;
    EventCostDate: string;
}

export interface EventCostAuto {
    EventCostAutoVehicule: number;
    EventCostAutoCostKilometers: number;
    EventCostAutoCostHighwayToll: number;
}

export interface EventCostType {
    CostTypeId: number
    CosTypeExpense: boolean
    CostTypeAuto: boolean
    CostTypeCode: string
    CostTypeLabel: string
    CostTypeMaxAmount: number
    CostTypeRateTVA: number
    CostTypeRebillable : boolean
    CostTypeWithAmountTVA : boolean
    CostTypeWithKmCO2 : boolean
    CostTypeWithOvernight: boolean
}

export interface EventCostTypeObject {
    CostTypes: EventCostType[]
    Error: Error
}

export interface EventCostObject {
    EventCost: EventCost
    Error: Error
}

export interface EventCostTabObject {
    EventCosts: EventCost[]
    Error: Error
}

export function constructorEventCostType(): EventCostType {
    return {
        CostTypeId: 0,
        CosTypeExpense:  false,
        CostTypeAuto: false,
        CostTypeCode: '',
        CostTypeLabel: '',
        CostTypeMaxAmount: 0,
        CostTypeRateTVA: 0,
        CostTypeRebillable :  false,
        CostTypeWithAmountTVA :  false,
        CostTypeWithKmCO2 :  false,
        CostTypeWithOvernight:  false,
    }
}

export function constructorEventCost(): EventCost {
    return {
        EventCostId: 0,
        EventCostDetail: '',
        EventCostTTC: 0,
        EventCostTVA: 0,
        EventCostOvernight: 0,
        EventCostKmCO2: 0,
        EventCostRebillable: false,
        EventCostExpense: false,
        EventCostDate: '',
        EventCostType: constructorEventCostType()
    }
}

export function constructorEventCostAuto(): EventCostAuto {
    return {
        EventCostAutoVehicule: 0,
        EventCostAutoCostKilometers: 0,
        EventCostAutoCostHighwayToll: 0,
    }
}

