import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatDate',
  standalone: true
})
export class FormatDatePipe implements PipeTransform {

  transform(value: string | number): Date | string {
    if (!value) return '';

    const valueStr = value.toString();
    if (valueStr.length !== 8) return valueStr; // Vérification du format attendu

    const year = parseInt(valueStr.substring(0, 4), 10);
    const month = parseInt(valueStr.substring(4, 6), 10) - 1; // Mois commence à 0
    const day = parseInt(valueStr.substring(6, 8), 10);

    return new Date(year, month, day);
  }

}
