<div class="planning-container">
    <div class="planning-header">
        <app-icon class="planning-close-button" (click)="backToCalendar()" [iconLabel]="'close'"></app-icon>
        <h2 class="regular planning-title">Planning consultant(s)</h2>
        <app-icon class="planning-tooltip-button" (click)="closeTooltipUser()" [iconLabel]="'planning'"></app-icon>
        @if (isTooltipPlanningOpen) {
            <app-tooltip-user (tooltipUserClose)="closeTooltipUser($event)" (tooltipListUserSelected)="getUserSelectedFromTooltip($event)" [selectUserList]="tabUserSelected" [typeTooltip]="'user-planning'"></app-tooltip-user>
        }
    </div>
    <div class="planning-date">
        <div class="planning-date-form">
            <h5 class="bold field-name">Date :</h5> 
            <div class="special-input-date">
                <input (ngModelChange)="dateChange($event)" [(ngModel)]="dateValue" type="date" #datePicker> 
                <app-icon (click)="datePicker.showPicker()" [iconLabel]="'date-calendar'" class="special-input-date-icon"></app-icon>
            </div>
        </div>
        @if (dateSelected) {
            <div class="planning-date-list">
                <h4 class="bold" [ngClass]="{'planning-date-selected': dateSelected == dateToday}" (click)="selectDate(dateToday)">{{dateToday | date: 'dd/MM/yyyy'}}</h4>
                <h4 class="bold" [ngClass]="{'planning-date-selected': dateSelected == dateTomorow}" (click)="selectDate(dateTomorow)">{{dateTomorow | date: 'dd/MM/yyyy'}}</h4>
                <h4 class="bold" [ngClass]="{'planning-date-selected': dateSelected == dateTotomorow}" (click)="selectDate(dateTotomorow)">{{dateTotomorow | date: 'dd/MM/yyyy'}}</h4>
            </div>
        }
    </div>
    @if(!isLoaded) {
    <div class="planning-info-user">
        <h4 class="regular">Afin de consulter le planning à la date sélectionnée ci-dessus : veuillez choisir au moins un utilisateur dans la liste en haut à droite de votre écran</h4>
    </div>
    }
    <div class="planning-user-list">
        @if (isLoaded && dateSelected) {
            <div *ngFor="let userEvent of tabDayUserEvent" class="planning-user-list-content">
                <div class="planning-user-list-content-header">
                    <div class="title">
                        <h3 class="bold">{{userEvent.DayUserEventUser.UserShortName}}</h3>
                        <h5 class="bold">{{userEvent.DayUserEventUser.UserEntityLabel}}</h5>
                    </div>
                    <app-icon class="icon" [iconLabel]="'cross'" (click)="unselectUser(userEvent.DayUserEventUser)"></app-icon>
                </div>
                @if (userEvent.DayUserEvents.length > 0) {
                    <div class="planning-user-event">
                        <app-event-card *ngFor="let event of userEvent.DayUserEvents" [eventObj]="event" [deletableEvent]="false" [clickableEvent]="false"></app-event-card>
                    </div>
                }
            </div>
        }
    </div>
</div>