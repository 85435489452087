import { CommonModule, DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { IconComponent } from "../widgets/icon/icon.component";
import { Router } from '@angular/router';
import { Event } from '../models/event';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { UserService } from '../services/user.service';
import { User } from '../models/user';
import { Combo } from '../models/combo-object';
import { ParamService } from '../services/param.service';
import { EventService } from '../services/event.service';
import { NetworkStatusService } from '../services/network-status.service';
import { Observable } from 'rxjs';
import { log } from 'node:console';

@Component({
  selector: 'app-event-form',
  standalone: true,
  imports: [IconComponent, FormsModule, ReactiveFormsModule, CommonModule],
  providers: [DatePipe],
  templateUrl: './event-form.component.html',
  styleUrl: './event-form.component.scss'
})

export class EventFormComponent {

  event!: Event;
  eventForm!: FormGroup
  googleMapsLink!: string;
  userEvent!: User;
  stateCombo!: Combo;
  isUserAuthorized: boolean = false;
  isBillableOfferedVisible: boolean = false;
  online$ : Observable<boolean>;
  private changed : boolean = false;

  constructor(
    private router: Router,
    private userSvc: UserService,
    private paramSvc: ParamService,
    private eventSvc: EventService,
    private datePipe: DatePipe,
    statusSvc: NetworkStatusService
  ) {
    //Param nagivation
    const navigation = this.router.getCurrentNavigation();
    
    const state = navigation?.extras.state as { _event: Event, _changed: boolean };
    if (state) {
        this.event = state._event;
        this.changed = state._changed;
    }

    //Get user chosen for title 
    this.userEvent = this.userSvc.getUserChosen();   
     
    this.checkUserAuthorisation();

    //Form
    this.initEventForm();

    //Get today date
    let currentDate: string | null = this.datePipe.transform(new Date(),"yyyyMMdd");
    //Get Combo State
    let _tempComboList = this.paramSvc.comboList.find(_combo => _combo.ComboLabel == "Statut")
    if (_tempComboList) {
      //Remove "Réalisé" from State list because we choose this option if the event isn't past yet
      if (currentDate && (this.event.EventDate > currentDate)) {
        _tempComboList.ComboValues = _tempComboList.ComboValues.filter(_comboValue => _comboValue.ComboValueLabel != "Réalisé");
      }
      this.stateCombo = _tempComboList;
    }

    //Display this field only if all this conditions are completed
    this.isBillableOfferedVisible = this.event.EventState == 1 && this.event.EventFA.FicheActionIsBillable && this.event.EventFA.FicheActionPrestTypeCorr == 1 && this.userEvent.UserActivityRight;

    this.online$ = statusSvc.isOnline$;

    //Listen statu event change
    this.eventForm.get('eventState')?.valueChanges.subscribe(value => {      
      //Check before change into "Réalisé"
      if(this.event.EventState != 3 && value == 3) {
        //Need to be sure to have at least 1 cost or the checkbox "Aucun frais" checked
        if (this.event.EventCosts.length == 0 && !this.event.EventisCost) {
          alert("Veuillez confirmer vos frais avant de pouvoir modifier le statut à réalisé");
          this.eventForm.get('eventState')?.patchValue(this.event.EventState, { emitEvent: false });
          this.eventForm.get('eventState')?.markAsPristine();
        }
      }
    });
  }

  initEventForm(): void {
    this.eventForm = new FormGroup({
      eventDate: new FormControl({value: this.convertDate(this.event.EventDate), disabled: !this.isUserAuthorized}, [Validators.required]),
      eventStart: new FormControl({value: this.event.EventStart, disabled: !this.isUserAuthorized}, [Validators.required]),
      eventEnd: new FormControl({value: this.event.EventEnd, disabled: !this.isUserAuthorized}, [Validators.required]),
      eventState: new FormControl({value: this.event.EventState, disabled: !this.isUserAuthorized}, [Validators.required]),
      eventConfirmConsult: new FormControl({value: this.event.EventConfirmConsult, disabled: !this.isUserAuthorized}, [Validators.required]),
      eventOffered: new FormControl({value: this.event.EventOffered, disabled: !this.isUserAuthorized}, [Validators.required]),
      eventNumberDay: new FormControl({value: this.event.EventNumberDay > 0 ? this.event.EventNumberDay : '', disabled: !this.isUserAuthorized}, [Validators.required, Validators.pattern(/^[0-9]+$/)]),
      eventRoom: new FormControl({value: this.event.EventRooms, disabled: !this.isUserAuthorized}, [Validators.required]),
      eventRemote: new FormControl({value: this.event.EventRemote, disabled: !this.isUserAuthorized}, [Validators.required]),
      eventPlace: new FormControl({value: this.event.EventPlace, disabled: !this.isUserAuthorized}, [Validators.required]),
      eventCompanionship: new FormControl({value: this.event.EventCompanionship, disabled: !this.isUserAuthorized}, [Validators.required]),
      eventPlannedActions: new FormControl({value: this.event.EventPlannedActions, disabled: !this.isUserAuthorized}, [Validators.required, Validators.maxLength(150)]),
      eventConstraint: new FormControl({value: this.event.EventConstraint, disabled: !this.isUserAuthorized}, [Validators.required, Validators.maxLength(150)])
    });
  }
  
  backToCalendar() {
    //this.location.back();
    const params = {} as any;
    if(this.changed){
      params["refresh"] = "";
    }

    this.router.navigate(['calendar/', this.userSvc.getUserChosen().UserId, this.event.EventDate], {queryParams: params});
  }

  //Update switch Offered
  changeInputOffered(newValue: boolean) {
    if (this.isUserAuthorized) {
      this.event.EventOffered = newValue;
      this.eventForm.get('eventOffered')?.setValue(newValue);
      this.eventForm.markAsDirty();
    }
  }

  //Update switch Remote
  changeInputRemote(newValue: boolean) {
    if (this.isUserAuthorized) {
      this.event.EventRemote = newValue;
      this.eventForm.get('eventRemote')?.setValue(newValue);
      this.eventForm.markAsDirty();
    }
  }

  convertDate(date: string): string {
    if (date.length !== 8) {
      console.error('Format de date incorrect');
      return '';
    }
    const year = date.substring(0, 4);
    const month = date.substring(4, 6);
    const day = date.substring(6, 8);
    return `${year}-${month}-${day}`;
  }

  modifyEvent(_forcedSecondtTime: boolean = false, backToCalendar: boolean = true) {
    this.setNewObjectEvent();
    this.eventSvc.modifEvent(this.event, +_forcedSecondtTime).subscribe({
      next: (_response) => {
        if (_response.Error.ErrorCode == 206) {
          if (confirm(_response.Error.ErrorMessage)) {
            this.modifyEvent(true, backToCalendar);
          } else {
            return;
          }
        } else {
          this.changed = true;
          if (backToCalendar) {
            this.backToCalendar();
          }
        }
      },
      error: (_error) => {
        console.error("Erreur lors de la modification de l'évènement", _error);
        if (_error.error) {
          alert("Erreur lors de la modification de l'évènement : " + _error.error.Error.ErrorMessage);
        }
      }
    });
  }

  setNewObjectEvent() {
    let tranformedDate: string | null = this.datePipe.transform(this.eventForm.get('eventDate')?.value, "yyyyMMdd");
    this.event.EventDate = tranformedDate ? tranformedDate : '';
    this.event.EventStart = this.eventForm.get('eventStart')?.value;
    this.event.EventEnd = this.eventForm.get('eventEnd')?.value;
    this.event.EventState = this.eventForm.get('eventState')?.value;
    this.event.EventConfirmConsult = this.eventForm.get('eventConfirmConsult')?.value;
    this.event.EventOffered = this.eventForm.get('eventOffered')?.value;
    this.event.EventNumberDay= this.eventForm.get('eventNumberDay')?.value;
    this.event.EventRemote = this.eventForm.get('eventRemote')?.value;
    this.event.EventPlace = this.eventForm.get('eventPlace')?.value;
    this.event.EventCompanionship = this.eventForm.get('eventCompanionship')?.value;
    this.event.EventPlannedActions = this.eventForm.get('eventPlannedActions')?.value;
    this.event.EventConstraint = this.eventForm.get('eventConstraint')?.value;
  }

  openCostList() {
    if (this.eventForm.dirty && this.isUserAuthorized) {
      if (confirm("Vous avez modifié cet évenement voulez vous enregistrer ?")) {
        this.modifyEvent(false, false);
      } 
    }

    let _event = this.event;
    this.router.navigate(['/event', this.event.EventId, 'costList'], {
      state: { _event }
    });
  }

  openPlanningFa() {
    let _event = this.event;
    this.router.navigate(['/event', this.event.EventId, 'planning-fa'], {
      state: { _event }
    });
  }

  openLink(_link: string) {
    if (_link) {
      window.open(_link, "_blank");
    }
  }

  //Authorisation User
  checkUserAuthorisation() {    
    this.isUserAuthorized = this.userSvc.getUserAuthorisation(this.userEvent.UserId);
  }

  openGoogleMapAdress() {
    this.googleMapsLink = this.generateGoogleMapsLink(this.eventForm.get('eventPlace')?.value);
    window.open(this.googleMapsLink, "_blank");
  }

  generateGoogleMapsLink(address: string): string {
    const encodedAddress = encodeURIComponent(address);
    return `https://www.google.com/maps/search/?api=1&query=${encodedAddress}`;
  }
}
