import { HttpClient } from '@angular/common/http';
import { EntityObject } from '../models/entity-object';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { constructorUser, User, UserObject } from '../models/user';
import { UserNote } from '../models/user-note';
import { UserNoteObject } from '../models/user-note-object';
import { ActivatedRoute, Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { AuthenticationResult } from '@azure/msal-browser';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private userSubject: BehaviorSubject<User | null>;
  public userChosen?: User | null;
  public user$: Observable<User | null>;  
  
  public userConnected: User = constructorUser()

  constructor(
    private http: HttpClient,
    private router : Router
  ) {
      this.userSubject = new BehaviorSubject<User | null>(this.userConnected);
      this.user$ = this.userSubject.asObservable();         
      //this.router.navigate(['calendar/', this.userConnected.UserId]);
  }

  //getUserInfo
  setAuthentification() {
    /* let _userConnected: User = {
      UserId: 65,
      UserName: "65-GONY Jérémy",
      UserShortName: "GONY Jérémy",
      UserEntityLabel: "SAS POP"
    }; 

    this.userConnected = _userConnected; */
    return this.http.get<UserObject>('/api/User');
  }

  getUserAuthenticated() {
    this.setAuthentification().subscribe(
      {
        next: (_userObject: UserObject) => {
          this.userConnected = _userObject.User;
          this.userSubject.next(this.userConnected);
          this.router.navigate(['calendar/', this.userConnected.UserId]);
        },
        error: _error => {
          console.error("Erreur lors du chargement des données de l'utilisateur", _error);
          if (_error.error) {
            alert("Erreur lors du chargement des données de l'utilisateur : " + _error.error.Error.ErrorMessage);
          }
        }
      }
    )
  }

  isUserAuthenticated() {
    return this.userConnected.UserId > 0;
  }

  getEntityList(): Observable<EntityObject> {
    return this.http.get<EntityObject>('/api/UserList');
  }

  //Update CurrentUser
  setUserChosen(_user: User) {
    this.userChosen = _user;
    this.userSubject.next(_user);
    this.updatePrimaryColor(this.isAnotherUser());
    this.router.navigate(['calendar/', _user.UserId]);
    //Reload UserNote TODO
  } 

  getUserChosen() {
    return this.userChosen ? this.userChosen : this.userConnected;
  }

  removeUserChosen() {
    this.userChosen = null;
    this.setUserChosen(this.userConnected);
    this.updatePrimaryColor(this.isAnotherUser());
    //Reload UserNote TODO
    //Reset filter TODO
  }

  //Compare currentUser and UserConnected
  isAnotherUser() : boolean {
      if (this.userChosen == null) return false;
      return !(this.userChosen.UserId == this.userConnected.UserId);
  }

  //Update Primary color based on currentUser (green <-> orange)
  updatePrimaryColor(_isAnotherUser: boolean) {
      const newColor = _isAnotherUser ? '#F1892A' : '#6BBD45';
      document.documentElement.style.setProperty('--color-main-primary-orange-or-green', newColor);
  }

  //NOTE *****
  getUserNote(): Observable<UserNoteObject> {
    return this.http.get<UserNoteObject>('/api/UserNote');
  }

  setUserNote(_userNote: UserNote): Observable<UserNote> {
    return this.http.post<UserNote>('/api/UserNote', _userNote );
  }

  getUserAuthorisation(targetUserId: number) : boolean {
    //If user contain 1 occurence with "ALL" thats mean he has right on everyone
    //Other case an user always have his id in the array and can have more id
    if (this.userConnected.UserAuthUsers[0] == "ALL") {
      return true;
    }
    
    const isTargetFound = this.userConnected.UserAuthUsers.find(_userId => _userId == String(targetUserId));
    
    if (isTargetFound != undefined) {
      return true;
    }

    return false;
  }
}
