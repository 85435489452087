import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatDateInput',
  standalone: true
})
export class FormatDateInputPipe implements PipeTransform {

  transform(value: string | number): string {
    if (!value) return '';

    const valueStr = value.toString();
    if (valueStr.length !== 8) return valueStr;

    const year = valueStr.substring(0, 4);
    const month = valueStr.substring(4, 6);
    const day = valueStr.substring(6, 8);

    return `${year}-${month}-${day}`;
  }
  
}
