import { CommonModule, DatePipe, DecimalPipe } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { IconComponent } from '../widgets/icon/icon.component';
import { ComboValue } from '../models/combo-object';
import { constructorEventCost, constructorEventCostAuto, constructorEventCostType, EventCost, EventCostAuto, EventCostType } from '../models/event-cost';
import { FicheAction } from '../models/entity';
import { UserService } from '../services/user.service';
import { Event } from '../models/event';
import { FormatDateInputPipe } from '../pipe/format-date-input.pipe';
import { FormatDatePipe } from '../pipe/format-date.pipe';

@Component({
  selector: 'app-tooltip-cost',
  standalone: true,
  imports: [IconComponent, CommonModule, FormsModule, ReactiveFormsModule],
  providers: [DecimalPipe, DatePipe, FormatDateInputPipe],
  templateUrl: './tooltip-cost.component.html',
  styleUrl: './tooltip-cost.component.scss'
})

export class TooltipCostComponent {

  tooltipOpen: boolean = true;

  typeComboList!: EventCostType[];
  carCombo!: ComboValue[];
  costForm!: FormGroup;
  costFormAuto!: FormGroup;

  newCost!: EventCost;
  newCostAuto!: EventCostAuto;

  isTravelCost: boolean = false;
  ficheAction!: FicheAction;
  eventGlobal!: Event;
  costTypeSelected: EventCostType = constructorEventCostType();

  //Listen open/close of the component
  @Input() set openCloseTooltip(_isOpen: boolean) {
    this.tooltipOpen = !this.tooltipOpen;
  };

  @Input() set event(_event: Event) {
    this.eventGlobal = _event;
    this.ficheAction = _event.EventFA;
    //Init form after receiving FicheAction
    this.initFormCost();
    this.initCostAutoForm();
  };

  @Output() addEventCost: EventEmitter<EventCost> = new EventEmitter<EventCost>();
  @Output() addEventCostAuto: EventEmitter<EventCostAuto> = new EventEmitter<EventCostAuto>();

  constructor(
    private userSvc: UserService,
    private datePipe: DatePipe,
    private formatDateInputPipe: FormatDateInputPipe
  ) {}

  ngOnInit() {
    //Get Combo Type Cost
    let _tempTypeCostList = localStorage.getItem("PPL-TypeCostListObject");
    if (_tempTypeCostList) {
      //Don't display CostTypeAuto 
      this.typeComboList = JSON.parse(_tempTypeCostList).filter((_type: EventCostType)=> _type.CostTypeAuto == false);
    }
    
    //Get Combo State
    let _tempListAuto = localStorage.getItem("PPL-VehiculeList");
    if (_tempListAuto) {
      this.carCombo = JSON.parse(_tempListAuto);
    }
  }

  initFormCost(resetCostType: boolean = false, resetCostDate: boolean = true) {
    let _tempDateValue: string = "";

    if (!resetCostDate) {
      _tempDateValue = this.costForm.get('costDate')?.value;
    }

    const group: any = {};

    group["costType"] = new FormControl(resetCostType ? 0 : this.costTypeSelected.CostTypeId, [Validators.required]);
    group["costDetail"] = new FormControl(this.costTypeSelected.CostTypeLabel ? this.costTypeSelected.CostTypeLabel : '', [Validators.required]);
    group["costExpense"] = new FormControl(this.costTypeSelected.CosTypeExpense ? this.costTypeSelected.CosTypeExpense : false, [Validators.required]);
    group["costRebillable"] = new FormControl(this.costTypeSelected && this.costTypeSelected.CostTypeRebillable && this.ficheAction.FicheActionIsBillable, [Validators.required]);
    group["costTTC"] = new FormControl(0, [Validators.required, Validators.pattern(/^-?\d*[.,]?\d{0,2}$/)]);
    group["costDate"] = new FormControl(resetCostDate ? this.formatDateInputPipe.transform(this.eventGlobal.EventDate) : _tempDateValue, [Validators.required]);

    if (this.costTypeSelected.CostTypeWithAmountTVA) group["costTVA"] = new FormControl(0, [Validators.required, Validators.pattern(/^-?\d*[.,]?\d{0,2}$/)]);

    if (this.costTypeSelected.CostTypeWithOvernight) group["costOvernight"] = new FormControl(1, [Validators.required, Validators.min(1)]);

    if (this.costTypeSelected.CostTypeWithKmCO2) group["costKmCO2"] = new FormControl(0, [Validators.required, Validators.min(1)]);

    this.costForm = new FormGroup(group);
  }

  initCostAutoForm(): void {
    this.costFormAuto = new FormGroup({
      costVehicule: new FormControl(this.userSvc.getUserChosen().UserAutoVehicule, [Validators.required]),
      costKm: new FormControl({value: '', disabled: true}, [Validators.pattern(/^-?\d*[.,]?\d{0,2}$/)]),
      costKilometers: new FormControl(this.ficheAction.FicheActionKilometers, [Validators.required, Validators.pattern(/^-?\d*[.,]?\d{0,2}$/), Validators.min(1)]),
      costHighwayToll: new FormControl(this.ficheAction.FicheActiontHighwayToll ? this.ficheAction.FicheActiontHighwayToll : 0, [Validators.required, Validators.pattern(/^-?\d*[.,]?\d{0,2}$/)]),
    });
  }

  createCost() {

    if (this.isTravelCost) {
      this.setNewObjectEventCostAuto();

      if (this.costFormAuto.valid) {
        this.addEventCostAuto.emit(this.newCostAuto);        
        this.tooltipOpen = false;
        this.costTypeSelected = constructorEventCostType();
        //Reset both forms
        this.initCostAutoForm();
        this.initFormCost(true);
      } else {
        alert("Données saisies dans le formulaire invalides")
      }
    } else {
      this.setNewObjectEventCost();
      
      if (this.costForm.valid && this.newCost.EventCostType.CostTypeId > 0) {        
        this.addEventCost.emit(this.newCost);
        this.tooltipOpen = false;
        this.costTypeSelected = constructorEventCostType();

        //Reset both forms
        this.initCostAutoForm();
        this.initFormCost(true);
      } else {
        alert("Données saisies dans le formulaire invalides")
      }
    }
  }

  setNewObjectEventCost() {
    //Reset newObject
    this.newCost = constructorEventCost();

    if (this.costForm.get('costTVA')?.value == '') {
      this.costForm.get('costTVA')?.setValue('0');
    }
    if (this.costForm.get('costTTC')?.value == '') {
      this.costForm.get('costTTC')?.setValue('0');
    }
    
    //Get value Object
    this.newCost.EventCostType = this.costTypeSelected;
    this.newCost.EventCostDetail = this.costForm.get('costDetail')?.value;
    this.newCost.EventCostTTC = Number(String(this.costForm.get('costTTC')?.value).replace(',', '.'));
    this.newCost.EventCostTVA = Number(String(this.costForm.get('costTVA')?.value ? this.costForm.get('costTVA')?.value : 0).replace(',', '.'));
    this.newCost.EventCostExpense = this.costForm.get('costExpense')?.value;
    this.newCost.EventCostRebillable = this.costForm.get('costRebillable')?.value;
    this.newCost.EventCostOvernight = this.costForm.get('costOvernight')?.value ? this.costForm.get('costOvernight')?.value : 0;
    this.newCost.EventCostKmCO2 = this.costForm.get('costKmCO2')?.value ? this.costForm.get('costKmCO2')?.value : 0;
    let tranformedDate: string | null = this.datePipe.transform(this.costForm.get('costDate')?.value, "yyyyMMdd");
    this.newCost.EventCostDate = tranformedDate ? tranformedDate : '';
  }

  setNewObjectEventCostAuto() {
    //Reset newObjectAuto
    this.newCostAuto = constructorEventCostAuto();

    this.newCostAuto.EventCostAutoVehicule = Number(this.costFormAuto.get('costVehicule')?.value);
    this.newCostAuto.EventCostAutoCostKilometers = Number(String(this.costFormAuto.get('costKilometers')?.value).replace(',', '.'));
    this.newCostAuto.EventCostAutoCostHighwayToll = Number(String(this.costFormAuto.get('costHighwayToll')?.value).replace(',', '.'));
  }

  onChangeCostType(_costTypeId: number) {

    if (!this.typeComboList) return;

    let _tempCostType = this.typeComboList.find(_type => _type.CostTypeId == _costTypeId);
    
    if (_tempCostType) {
      //Update selected type 
      this.costTypeSelected = _tempCostType;

      //Init new form
      this.initFormCost(false, false);
    }
  }
}
